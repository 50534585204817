/* eslint-disable @typescript-eslint/no-explicit-any */
import { inject, Injectable, signal } from '@angular/core'
import { firstValueFrom, forkJoin } from 'rxjs'
import { EntriesService, ExternalFormsField, ExternalFormsService } from 'superlikers-microsites-api'

import { Kpi, KpiType } from '@kpis/models/kpis'

@Injectable({
  providedIn: 'root'
})
export class KpisService {
  externalsFormsService = inject(ExternalFormsService)
  entriesService = inject(EntriesService)

  #kpisSignal = signal<Kpi[]>([])
  kpis = this.#kpisSignal.asReadonly()

  // External avance_metas
  #externalSignal = signal<Record<string, string>>({})
  external = this.#externalSignal.asReadonly()

  constructor() {
    this.getKpis()
    this.getExternalData()
  }

  async getKpi(id: string) {
    const kpis = this.kpis()
    const kpi = kpis.find(item => item.id === id)
    if (kpi) return kpi

    try {
      const list$ = this.externalsFormsService.getExternalsFormList(1)
      const list = await firstValueFrom(list$)
      const kpiForm = list.data.find(item => item.category === 'kpis' && item.slug === id)

      if (!kpiForm) throw new Error(`No hay información para el kpi ${id}`)

      const fieldsResponse$ = this.externalsFormsService.getExternalsFormFields(kpiForm._id)
      const fieldsResponse = await firstValueFrom(fieldsResponse$)

      const fields = this.getFieldsFromExternalForm(fieldsResponse.object.fields)

      return fields as Kpi
    } catch (err: any) {
      console.log(err)
      return null
    }
  }

  async getKpis() {
    const list$ = this.externalsFormsService.getExternalsFormList(1)
    const list = await firstValueFrom(list$)
    const kpisForms = list.data.filter(item => item.category === 'kpis')

    const fields$ = kpisForms.map(item => this.externalsFormsService.getExternalsFormFields(item._id))
    const fields = await firstValueFrom(forkJoin(fields$))

    const kpis = fields.map(item => {
      const itemFields = item.object.fields
      return this.getFieldsFromExternalForm(itemFields)
    })

    this.#kpisSignal.set(kpis)
  }

  async getExternalData() {
    const response$ = this.entriesService.getExternals<Record<string, string>>({ atype: 'avance_metas' })
    const response = await firstValueFrom(response$)

    const external = response.data.at(0)
    this.#externalSignal.set(external?.data ?? {})
  }

  getDetails(kpiId: string, type: KpiType) {
    const data = this.external()

    const goal = parseFloat(data[`${kpiId}_meta_mes_${type.type}`])
    const progress = parseFloat(data[`${kpiId}_avance_actual_${type.type}`])
    const percentage = goal > 0 ? progress / goal : 0

    return { goal, progress, percentage }
  }

  private getFieldsFromExternalForm(fields: ExternalFormsField[]) {
    return Object.fromEntries(
      fields.map(field => {
        const value = this.getValueFromExternalForms(field)
        return [field.name, value]
      })
    ) as Kpi
  }

  private getValueFromExternalForms(data: ExternalFormsField) {
    if (data.help_text === 'json') {
      return JSON.parse(data.default_value)
    }

    if (data.help_text === 'boolean') {
      return data.default_value === 'True'
    }

    return data.default_value
  }
}
